import React from "react";
import { PageComponentProps } from "../../types/types";
import Layout from "../parts/Layout";
import { NewsletterSubscriptionForm } from "../parts/NewsletterSubscriptionForm";
import { Link } from "gatsby";

import * as pageStyles from "../global-styles/page.module.css";

export const Newsletter = (props: PageComponentProps) => {
  const { sn, urlDir } = props;
  const title = "Clairnote Newsletter";
  const description =
    "Subscribe to the Clairnote Newsletter to get occasional news and updates.";

  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <p>
          Subscribe to our newsletter to get occasional news and updates by
          email. It's a great way to find out when new blog posts are published.
          Typically there are between two and twelve messages per year.
        </p>
        <NewsletterSubscriptionForm sn={sn} />
        <p>
          Once you're subscribed, check out the{" "}
          <Link to={urlDir + "community/"}>Community</Link> page.
        </p>
      </article>
    </Layout>
  );
};
