import { PageProps } from "gatsby";
import React from "react";

import { Newsletter } from "../../components/pages/Newsletter";

import { dnProps } from "../../js/utils";

const NewsletterPage = (props: PageProps) => (
  <Newsletter {...{ ...dnProps, ...props }} />
);

export default NewsletterPage;
